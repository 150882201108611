
import { BSubtitle, BTabItem, BTabs, BTitle } from 'buetify/lib/components';
import BSimpleTable from 'buetify/lib/components/table/BSimpleTable';
import { defineComponent, computed, PropType } from 'vue';
import { toSlug } from '../../shared/composables/useSlug';
import { ComponentApiDescription } from './types';

interface Column {
	label: string;
	field: string;
}

const PropColumns: Column[] = [
	{ label: 'Name', field: 'name' },
	{ label: 'Description', field: 'description' },
	{ label: 'Type', field: 'type' },
	{ label: 'Required', field: 'required' },
	{ label: 'Values', field: 'values' },
	{ label: 'Default', field: 'default' }
];

const SlotColumns: Column[] = [
	{ label: 'Slot name', field: 'name' },
	{ label: 'Description', field: 'description' },
	{ label: 'Props', field: 'props' }
];

const EventsColumns: Column[] = [
	{ label: 'Name', field: 'name' },
	{ label: 'Description', field: 'description' },
	{ label: 'Parameters', field: 'parameters' }
];

const MethodsColumns: Column[] = [
	{ label: 'Name', field: 'name' },
	{ label: 'Description', field: 'description' },
	{ label: 'Parameters', field: 'parameters' },
	{ label: 'Return', field: 'return' }
];
export default defineComponent({
	name: 'api-view',
	components: {
		BSimpleTable,
		BTitle,
		BSubtitle,
		BTabs,
		BTabItem
	},
	props: {
		apis: {
			type: Array as PropType<ComponentApiDescription[]>,
			required: true
		}
	},
	setup(props) {
		return {
			PropColumns,
			SlotColumns,
			EventsColumns,
			MethodsColumns,
			data: computed(() => props.apis.map(api => ({ ...api, slug: toSlug(api.title) })))
		};
	}
});


import { BTitle } from 'buetify/lib/components';
import { defineComponent, computed } from 'vue';
import { useSlug } from '../../shared/composables';
import CodeView from '../codeView/CodeView.vue';
export default defineComponent({
	name: 'example-view',
	components: {
		BTitle,
		CodeView
	},
	props: {
		title: {
			type: String,
			required: false
		},
		code: {
			type: String,
			required: true
		},
		isVertical: {
			type: Boolean,
			default: false
		}
	},
	setup(props) {
		return {
			slug: useSlug(computed(() => props.title || ''))
		};
	}
});


import { BCheckbox } from 'buetify/lib/components';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'checkbox-sizes-example',
	components: {
		BCheckbox
	}
});


import { BCheckbox } from 'buetify/lib/components';
import { defineComponent, shallowRef } from 'vue';

export default defineComponent({
	name: 'checkbox-simple-example',
	components: {
		BCheckbox
	},
	setup() {
		const checked = shallowRef(false);
		const custom = shallowRef<'Yes' | 'No'>('Yes');

		return {
			checked,
			custom
		};
	}
});
